import React from 'react';
import SpineImage from '../assets/Spine1.jpg'; // Update with the correct spine-related image
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';
import '../Needed/Eye.css'; // Assuming shared styles
import logo from '../assets/logo.png';

const Spine = () => {
  return (
    <>
      <div className="text-white bg-gray-900 w-full">
        {/* Mobile Navbar */}
        <div className="lg:hidden z-0">
          <Navbar />
        </div>

        {/* Desktop Navbar */}
        <div className="hidden md:block justify-evenly px-8 py-8 mx-auto">
          <img
            src={logo}
            alt="StuFit"
            className="logo"
          />
          <nav className="nav-links hidden md:flex space-x-4 text-white ml-10 mr-10">
            <span className="brand-name text-white font-bold mr-11">StuFit</span>
            <Link to="/" className="hover:text-blue-400">HOME</Link>
            <Link to="/about" className="hover:text-blue-400">ABOUT</Link>
            <Link to="/services" className="hover:text-blue-400">SERVICES</Link>
            <Link to="/contact" className="hover:text-blue-400">CONTACT</Link>
          </nav>
        </div>

        <div className="py-6 px-4 sm:px-10">
          <header className="header text-center mb-8 mt-20">
            <h1 className="text-4xl sm:text-5xl font-bold mt-15">
              Spine & Posture Assessment for Children
            </h1>
            <img
              src={SpineImage}
              alt="Child maintaining posture"
              className="header-image mx-auto mt-4 w-full sm:w-4/5 h-auto object-cover"
            />
            <p className="caption mt-2 text-lg sm:text-xl text-gray-300">
              Good posture is essential for children during their developmental years. Proper spine alignment and posture
              ensure optimal physical health and play a crucial role in their long-term well-being.
            </p>
          </header>

          <main>
            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">
                Why Spine and Posture Health is Important
              </h2>
              <p className="mt-2 text-lg sm:text-xl text-gray-300">
                A child's spine grows rapidly, especially during their early years and adolescence. Poor posture during
                these critical phases can lead to issues such as back pain, fatigue, and improper alignment. Teaching
                children to maintain a healthy posture reduces the risk of musculoskeletal problems in adulthood.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">
                Common Posture Issues in Children
              </h2>
              <ul className="list-disc list-inside mt-2 text-lg sm:text-xl text-gray-300">
                <li>
                  <strong>Slouching:</strong> Often caused by prolonged sitting and inadequate back support, slouching can
                  strain muscles and misalign the spine.
                </li>
                <li>
                  <strong>Forward Head Posture:</strong> Common due to excessive screen time, this posture strains the
                  neck and upper back.
                </li>
                <li>
                  <strong>Scoliosis:</strong> A sideways curvature of the spine that can develop during growth spurts and
                  may require monitoring or treatment.
                </li>
                <li>
                  <strong>Kyphosis:</strong> Excessive rounding of the upper back, leading to a "hunched" appearance.
                </li>
                <li>
                  <strong>Uneven Shoulders:</strong> This can indicate poor posture habits or potential underlying spinal
                  conditions.
                </li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">
                Symptoms to Watch For
              </h2>
              <ul className="list-disc list-inside mt-2 text-lg sm:text-xl text-gray-300">
                <li>Frequent complaints of back or neck pain</li>
                <li>Difficulty sitting or standing for long periods</li>
                <li>Noticeable unevenness in shoulders or hips</li>
                <li>Tiredness or discomfort during physical activities</li>
                <li>Slumped or rounded shoulders</li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">
                How Spine & Posture Assessments Help
              </h2>
              <p className="mt-2 text-lg sm:text-xl text-gray-300">
                Spine and posture assessments identify misalignments, imbalances, and areas of strain in a child's body. By
                detecting these issues early, parents and healthcare providers can take steps to correct posture through
                exercises, ergonomic adjustments, or professional interventions if needed.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">
                Tips for Maintaining Good Posture
              </h2>
              <ul className="list-disc list-inside mt-2 text-lg sm:text-xl text-gray-300">
                <li>Encourage children to sit up straight with both feet flat on the floor.</li>
                <li>Limit screen time and encourage regular breaks for stretching.</li>
                <li>Promote physical activities that strengthen core muscles.</li>
                <li>Provide ergonomic furniture suitable for their height and size.</li>
                <li>Teach them to carry school bags correctly to avoid straining their back.</li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">
                When to Seek Professional Help
              </h2>
              <p className="mt-2 text-lg sm:text-xl text-gray-300">
                If your child shows signs of persistent pain, noticeable misalignment, or difficulty maintaining posture,
                consult a pediatrician, physical therapist, or chiropractor. Early intervention can prevent long-term
                complications and improve their quality of life.
              </p>
            </section>

            <footer className="mt-8">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">Conclusion</h2>
              <p className="mt-2 text-lg sm:text-xl text-gray-300">
                Promoting good posture in children not only enhances their physical health but also boosts confidence and
                energy levels. Regular assessments, early interventions, and healthy habits ensure they grow strong and
                thrive in all aspects of life.
              </p>
            </footer>
          </main>
        </div>
      </div>
    </>
  );
};

export default Spine;
