import React from 'react';
import { Link } from 'react-router-dom';

const CallToAction = () => {
  return (
    <section className="bg-blue-800 text-center py-12">
      <h2 className="text-4xl font-bold text-white mb-4">
        Take the Next Step for Your Child’s Health!
      </h2>
    
      <Link to="/contact">
        <button className="px-6 py-3 bg-white text-blue-800 font-semibold rounded hover:bg-gray-200 transition">
          GET STARTED
        </button>
      </Link>
    </section>
  );
};

export default CallToAction;
