import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { Link } from 'react-router-dom';
import bg from '../assets/backgroundvideo.mp4'
import logo from '../assets/logo.png'

import './HeroSection.css';

const HeroSection = () => {
  const heroTextRef = useRef();

  useEffect(() => {
    gsap.fromTo(
      heroTextRef.current,
      { y: -100, opacity: 0 },
      { y: 0, opacity: 1, duration: 2, ease: 'power3.out' }
    );
  }, []);

  return (
    <>
      <div className="hero-section">
        {/* Background Video */}
        <video autoPlay loop muted playsInline className="bg-video">
          <source
            src={bg}
            type="video/mp4"
          />
        </video>

        {/* Logo and Navigation */}
        <div className="top">
          <img
            src={logo}
            alt="StuFit"
            className="logo"
          />
          {/* Desktop Navigation */}
          <div className="hidden md:block">
            <nav className="nav-links hidden md:flex space-x-4 text-white">
              <span className="brand-name text-white font-bold">StuFit</span>
              <Link to="/">HOME</Link>
              <Link to="/about">ABOUT</Link>
              <Link to="/services">SERVICES</Link>
              <Link to="/contact">CONTACT</Link>
            </nav>
          </div>
          {/* Mobile Navigation */}
          <div className="block md:hidden text-center">
            <nav className="mobile-nav flex justify-center space-x-4 text-white">
              <Link to="/">HOME</Link>
              <Link to="/about">ABOUT</Link>
              <Link to="/services">SERVICES</Link>
              <Link to="/contact">CONTACT</Link>
            </nav>
          </div>
        </div>

        {/* Hero Text */}
        <div className="hero-text" ref={heroTextRef}>
          <h1 className="hero-heading">
            Is Your Child’s Health Being <span className="highlight">Monitored?</span>
          </h1>
          <h2>
            <span className="mew">Get a</span> <span className="dhrc">DHRC!</span>
          </h2>
        </div>
        <p className="report-card">Digital Health Report Card</p>
      </div>
    </>
  );
};

export default HeroSection;
