import React from 'react'
import Compo from '../components/Compo'
import { Link } from 'react-router-dom'
import aboutvideo from '../assets/Service.mp4'
import Footer from '../components/Footer'
import CallToAction from '../components/CallToAction'
import Navbar from '../components/Navbar'
import ChildrenHealth from '../components/ChildrenHealth'
import logo from '../assets/logo.png';

const Services = () => {
  return (
    <div>    <div className='lg:hidden z-0'>
    <Navbar/>
  </div>  <div className="relative h-[400px] overflow-hidden ">
    {/* Video Background */}
    <video autoPlay loop muted className="w-full h-full object-cover">
      <source src={aboutvideo} type="video/mp4" />
    </video>

    {/* Blackish Overlay */}
    <div className="absolute inset-0 bg-black opacity-70"></div>

    {/* Logo and Navigation */}
    <div className="absolute top-0 left-0 right-0 z-10 p-4 flex items-center justify-between hidden md:block">
    <Link to="/">
  <img src={logo} alt="StuFit" className="logo" />
</Link>
      <nav className="nav-links flex space-x-4 text-white">
      <Link to="/" className="brand-name text-white font-bold ml-7">
  StuFit
</Link>
        <Link to="/">HOME</Link>
        <Link to="/about">ABOUT</Link>
        <Link to="/services">SERVICES</Link>
        <Link to="/contact">CONTACT</Link>
      </nav>
    </div>

    {/* Centered Title */}
<div>
<h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-5xl font-bold text-white  z-10 shadow-xl  mr-80 ">
Services{'\u00A0\u00A0\u00A0'} {'\u00A0\u00A0\u00A0'}
    </h1>
</div>
  </div>
        <Compo/>
       <ChildrenHealth/>
      <CallToAction/>
        <Footer/>
    </div>
  )
}

export default Services
