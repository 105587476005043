import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Services from './Pages/Services';

import Compo from './components/Compo';
import CallToAction from './components/CallToAction';
import Eye from './Needed/Eye';
import OralHealth from './Needed/OralHealth';
import HearingTests from './Needed/HearingTests';
import Nutrition from './Needed/Nutrition';
import Spine from './Needed/Spine';

import PsychologicalSocial from './Needed/PsychologicalSocial';





const App = () => {
  return (
<>

<BrowserRouter>
      <Routes>
    
      
      <Route path="/CallToAction" element={<CallToAction />} />
      <Route path="/contact" element={<Contact />} />
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/Eye" element={<Eye />} />
        <Route path="/OralHealth" element={<OralHealth />} />
        <Route path="/HearingTests" element={<HearingTests/>} />
        <Route path="/Nutrition" element={<Nutrition/>} />
        <Route path="/PsychologicalSocial" element={<PsychologicalSocial/>} />
        <Route path="/Spine" element={<Spine/>} />

      </Routes>
</BrowserRouter>
</>
  )
}

export default App