import React from 'react';
import ChildHearing from '../assets/HearingChild.jpg'; // Update this to the relevant image for hearing tests
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import CallToAction from '../components/CallToAction';
import logo from '../assets/logo.png';

const HearingTests = () => {
  return (
    <>
      <div className="text-white bg-gray-900 w-full">
        <div className="lg:hidden z-0">
          <Navbar />
        </div>
        <div className="hidden md:block justify-evenly  mx-auto">
          <img
            src={logo}
            alt="StuFit"
            className="logo"
          />
          <nav className="nav-links hidden md:flex space-x-4 text-white ml-10 mr-10">
            <span className="brand-name text-white font-bold mr-11">StuFit</span>
            <Link to="/" className="hover:text-blue-400">HOME</Link>
            <Link to="/about" className="hover:text-blue-400">ABOUT</Link>
            <Link to="/services" className="hover:text-blue-400">SERVICES</Link>
            <Link to="/contact" className="hover:text-blue-400">CONTACT</Link>
          </nav>
        </div>

        <div className="py-6 px-10">
          <header className="header text-center mb-8 mt-20">
            <h1 className="text-5xl font-bold mt-15">
              Understanding Hearing Tests for Children
            </h1>
            <img
              src={ChildHearing}
              alt="Child taking a hearing test"
              className="header-image mx-auto mt-4 w-4/5 h-auto object-cover"
            />
            <p className="caption mt-2 text-lg text-gray-300">
              Early detection of hearing issues is crucial for a child’s development. Learn how hearing tests work and 
              why they are vital for children.
            </p>
          </header>

          <main>
            <section className="mb-6">
              <h2 className="text-2xl font-semibold text-blue-400">Why Hearing Tests Are Important</h2>
              <p className="mt-2 text-lg text-gray-300">
                Hearing plays a critical role in a child’s speech, language, and social development. Identifying hearing 
                loss early allows for timely interventions, helping children communicate effectively and succeed in school.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold text-blue-400">Types of Hearing Tests for Children</h2>
              <ul className="list-disc list-inside mt-2 text-lg text-gray-300">
                <li>
                  <strong>Newborn Hearing Screening:</strong> Typically done shortly after birth, using methods like 
                  otoacoustic emissions (OAE) or auditory brainstem response (ABR).
                </li>
                <li>
                  <strong>Behavioral Audiometry:</strong> Used for toddlers and preschoolers, observing their responses 
                  to sounds at different volumes and frequencies.
                </li>
                <li>
                  <strong>Pure-Tone Audiometry:</strong> Often used for older children, where they respond to tones played 
                  through headphones.
                </li>
                <li>
                  <strong>Tympanometry:</strong> Assesses the middle ear function by measuring eardrum movement in response 
                  to air pressure changes.
                </li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold text-blue-400">Signs Your Child May Need a Hearing Test</h2>
              <ul className="list-disc list-inside mt-2 text-lg text-gray-300">
                <li>Delayed speech or language development</li>
                <li>Frequent ear infections</li>
                <li>Difficulty following directions</li>
                <li>Turning up the volume on devices excessively</li>
                <li>Not responding to their name or sounds</li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold text-blue-400">Preparing Your Child for a Hearing Test</h2>
              <p className="mt-2 text-lg text-gray-300">
                Explain the process to your child in simple terms to alleviate any anxiety. Let them know the test is 
                painless and helps ensure their ears are healthy. Encourage them to follow instructions and make it a 
                positive experience.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl font-semibold text-blue-400">What Happens After a Hearing Test?</h2>
              <p className="mt-2 text-lg text-gray-300">
                If a hearing issue is detected, audiologists may recommend interventions such as hearing aids, therapy, 
                or further medical evaluation. Early treatment can significantly improve outcomes for children with 
                hearing loss.
              </p>
            </section>

            <footer className="mt-8">
              <h2 className="text-2xl font-semibold text-blue-400">Conclusion</h2>
              <p className="mt-2 text-lg text-gray-300">
                Regular hearing tests are an essential part of monitoring your child’s overall health. By ensuring their 
                hearing is functioning well, you’re supporting their ability to learn, communicate, and thrive.
              </p>
            </footer>
          </main>
        </div>

        <CallToAction />
        <Footer />
      </div>
    </>
  );
};

export default HearingTests;
