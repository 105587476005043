import React from 'react';
import PsychSocialImage from '../assets/psych.jpg'; // Replace with the correct image
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';
import '../Needed/Eye.css'; // Assuming shared styles
import logo from '../assets/logo.png';

const PsychologicalSocial = () => {
  return (
    <>
      <div className="text-white bg-gray-900 w-full">
        {/* Mobile Navbar */}
        <div className="lg:hidden z-0">
          <Navbar />
        </div>

        {/* Desktop Navbar */}
        <div className="hidden md:block justify-evenly px-8 py-8 mx-auto">
          <img
            src={logo}
            alt="StuFit"
            className="logo"
          />
          <nav className="nav-links hidden md:flex space-x-4 text-white ml-10 mr-10">
            <span className="brand-name text-white font-bold mr-11">StuFit</span>
            <Link to="/" className="hover:text-blue-400">HOME</Link>
            <Link to="/about" className="hover:text-blue-400">ABOUT</Link>
            <Link to="/services" className="hover:text-blue-400">SERVICES</Link>
            <Link to="/contact" className="hover:text-blue-400">CONTACT</Link>
          </nav>
        </div>

        <div className="py-6 px-4 sm:px-10">
          <header className="header text-center mb-8 mt-20">
            <h1 className="text-4xl sm:text-5xl font-bold mt-15">
              Psychological and Social Assessment for Children
            </h1>
            <img
              src={PsychSocialImage}
              alt="Psychological and Social Development"
              className="header-image mx-auto mt-4 w-full sm:w-4/5 h-auto object-cover"
            />
            <p className="caption mt-2 text-lg sm:text-xl text-gray-300">
              Psychological and social assessments are essential to understand a child’s emotional health and social
              well-being. These evaluations help identify challenges and guide interventions to foster positive growth.
            </p>
          </header>

          <main>
            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">
                Why Psychological and Social Health Matter
              </h2>
              <p className="mt-2 text-lg sm:text-xl text-gray-300">
                Psychological well-being and healthy social relationships are critical for children's emotional and
                cognitive development. Without adequate support, emotional or social struggles can hinder a child’s ability
                to learn, form relationships, and engage in life’s experiences.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">
                Common Psychological and Social Issues
              </h2>
              <ul className="list-disc list-inside mt-2 text-lg sm:text-xl text-gray-300">
                <li>
                  <strong>Anxiety and Stress:</strong> Academic pressures, social expectations, and family dynamics can lead
                  to feelings of anxiety and stress.
                </li>
                <li>
                  <strong>Depression:</strong> Symptoms may include persistent sadness, withdrawal, irritability, or lack of
                  interest in activities.
                </li>
                <li>
                  <strong>Behavioral Issues:</strong> Struggles with impulse control, aggression, or difficulty following
                  rules can indicate underlying emotional or social challenges.
                </li>
                <li>
                  <strong>Social Isolation:</strong> Difficulty making friends or engaging in social activities can lead to
                  feelings of loneliness.
                </li>
                <li>
                  <strong>Bullying:</strong> Exposure to bullying, either as a victim or perpetrator, can impact emotional and
                  social well-being.
                </li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">
                Signs to Watch For
              </h2>
              <ul className="list-disc list-inside mt-2 text-lg sm:text-xl text-gray-300">
                <li>Persistent sadness or irritability</li>
                <li>Withdrawal from friends, family, or activities</li>
                <li>Excessive fears, worries, or nervousness</li>
                <li>Changes in behavior, such as aggression or excessive talking</li>
                <li>Changes in school performance or avoiding school altogether</li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">
                Benefits of Psychological and Social Assessments
              </h2>
              <p className="mt-2 text-lg sm:text-xl text-gray-300">
                Psychological and social assessments help identify early signs of emotional and behavioral issues, enabling
                timely interventions. These assessments can include standardized questionnaires, interviews, and observations
                to evaluate a child’s emotional state, social interactions, and coping mechanisms.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">
                How Parents Can Support Their Child’s Mental Health
              </h2>
              <ul className="list-disc list-inside mt-2 text-lg sm:text-xl text-gray-300">
                <li>Encourage open communication about feelings and challenges.</li>
                <li>Support a healthy routine with sufficient sleep, physical activity, and balanced nutrition.</li>
                <li>Promote positive social interactions with peers and adults.</li>
                <li>Be mindful of the impact of social media and screen time on mental well-being.</li>
                <li>Seek professional help if signs of emotional distress or behavioral problems persist.</li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">
                When to Seek Professional Help
              </h2>
              <p className="mt-2 text-lg sm:text-xl text-gray-300">
                If a child is experiencing ongoing emotional distress, has difficulty interacting with others, or is
                struggling academically due to emotional or behavioral concerns, consulting a psychologist or counselor is
                recommended. Early intervention can make a significant difference in their overall development.
              </p>
            </section>

            <footer className="mt-8">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">Conclusion</h2>
              <p className="mt-2 text-lg sm:text-xl text-gray-300">
                Understanding a child’s psychological and social well-being is just as important as physical health. Regular
                assessments and early intervention are key to ensuring they develop the emotional resilience and social skills
                necessary to succeed in life.
              </p>
            </footer>
          </main>
        </div>
      </div>
    </>
  );
};

export default PsychologicalSocial;
