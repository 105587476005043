import React from 'react';
import Childeye from '../assets/childeye.webp';
import { Link } from 'react-router-dom';
import '../Needed/Eye.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CallToAction from '../components/CallToAction';
import logo from '../assets/logo.png';

const Eye = () => {
  return (
    < >
      <div className="  text-white bg-gray-900 w-full">
        <div className="lg:hidden z-0">
          <Navbar />
        </div>
        <div className="hidden md:block justify-evenly px-8 py-8 mx-auto">
          <img
            src={logo}
            alt="StuFit"
            className="logo"
            
          />
          <nav className="nav-links hidden md:flex space-x-4 text-white ml-10 mr-10">
            <span className="brand-name text-white font-bold mr-11">StuFit</span>
            <Link to="/" className="hover:text-blue-400">HOME</Link>
            <Link to="/about" className="hover:text-blue-400">ABOUT</Link>
            <Link to="/services" className="hover:text-blue-400">SERVICES</Link>
            <Link to="/contact" className="hover:text-blue-400">CONTACT</Link>
          </nav>
        </div>

       <div className='py-6 px-10'>
       <header className="header text-center mb-8 mt-20">
          <h1 className="text-5xl font-bold mt-15">School-Aged Vision: 6 to 18 Years of Age</h1>
          <img
            src={Childeye}
            alt="School-aged child in classroom"
            className="header-image mx-auto mt-4 w-4/5 h-auto object-cover"
          />
          <p className="caption mt-2 text-lg text-gray-300">
            Ensuring good vision during school years (6 to 18) is vital to children’s academic success and overall
            development. This period also sets the foundation for long-term visual health and well-being.
          </p>
        </header>

        <main>
          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-blue-400">Why Vision Health is Crucial for School-Aged Children</h2>
            <p className="mt-2 text-lg text-gray-300">
              Vision is one of the primary ways children learn, making visual health essential during school years. Poor
              vision can affect learning through difficulties in reading, writing, or recognizing objects, potentially
              hindering academic performance and self-confidence. Early detection and intervention are critical to
              ensuring every child achieves their full potential.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-blue-400">Common Vision Issues in School-Aged Children</h2>
            <ul className="list-disc list-inside mt-2 text-lg text-gray-300">
              <li>
                <strong>Myopia (Nearsightedness):</strong> The ability to see nearby objects clearly while distant
                objects appear blurry. It is common among school-going children due to excessive screen time and
                near-work activities.
              </li>
              <li>
                <strong>Hyperopia (Farsightedness):</strong> Difficulty seeing close objects clearly, often resulting
                in headaches and eyestrain.
              </li>
              <li>
                <strong>Astigmatism:</strong> A refractive error causing blurred or distorted vision at all distances
                due to an irregularly shaped cornea or lens.
              </li>
              <li>
                <strong>Eye Coordination Problems:</strong> Strabismus (crossed eyes) or convergence insufficiency
                can affect focusing ability.
              </li>
              <li>
                <strong>Lazy Eye (Amblyopia):</strong> Reduced vision in one eye due to improper development during
                childhood.
              </li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-blue-400">Symptoms to Watch For</h2>
            <ul className="list-disc list-inside mt-2 text-lg text-gray-300">
              <li>Frequent headaches or complaints of eye strain</li>
              <li>Squinting, rubbing eyes, or tilting the head to see better</li>
              <li>Difficulty reading, writing, or keeping up in class</li>
              <li>Double vision or blurry vision</li>
              <li>Disinterest in activities requiring clear vision, like drawing or playing ball</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-blue-400">The Role of Regular Eye Exams</h2>
            <p className="mt-2 text-lg text-gray-300">
              Routine eye exams help detect and treat vision problems before they hinder the child’s academic or social
              success. Comprehensive eye exams are recommended annually for school-aged children, even if no issues are
              reported. Optometrists or pediatric ophthalmologists can provide these exams and guide corrective measures
              such as glasses or contact lenses.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-blue-400">Preventing Eye Strain and Injuries</h2>
            <p className="mt-2 text-lg text-gray-300">
              Encouraging outdoor play, limiting screen time, and ensuring proper lighting while studying or reading can
              prevent eye strain. Using protective eyewear during sports and activities also reduces the risk of injuries.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-blue-400">Addressing Misdiagnoses</h2>
            <p className="mt-2 text-lg text-gray-300">
              Some vision issues may be misdiagnosed as behavioral problems or learning disabilities. It is essential to
              consult eye care professionals if any signs of visual discomfort or learning difficulties arise.
            </p>
          </section>

          <footer className="mt-15">
            <h2 className="text-2xl font-semibold text-blue-400">Conclusion</h2>
            <p className="mt-2 text-lg text-gray-300">
              Ensuring optimal vision during school years lays the foundation for academic, social, and emotional
              development. Regular eye exams, early intervention, and good eye care habits contribute to a lifetime of
              healthy vision.
            </p>
          </footer>
        </main>
       </div>
       <CallToAction/>
       <Footer/>
      </div>
    </>
  );
};

export default Eye;
