import React from 'react';

const HealthBenefits = () => {
  return (
    <div className="container mx-auto mt-20 mb-40 px-5">
      {/* Heading and subheading */}
      <div className="text-5xl font-bold text-center text-blue-900 mt-10 mb-4">
        How We Transform Children’s Health
      </div>
      <p className="text-gray-600 text-center mb-20 text-lg md:text-2xl px-5">
        Our services bring substantial benefits, empowering parents and schools to enhance children’s health monitoring and support.
      </p>

      {/* Flex container for benefit sections */}
      <div className="flex flex-wrap justify-between">
        <div className="w-full md:w-1/3 p-5 text-center">
          <hr className="border-t-2 border-gray-300 mb-4" />
          <h1 className="text-blue-900 text-left text-2xl md:text-4xl m-5 font-bold ">
          Early Detection Program

          </h1>
      
          <p className="text-gray-600 text-left text-base md:text-lg ">
            Our digital reports allow for early identification of health concerns, enabling timely intervention and better health outcomes for children.
          </p>
        </div>
        <div className="w-full md:w-1/3 p-5 text-center">
          <hr className="border-t-2 border-gray-300 mb-4" />
          <h1 className="text-blue-900 text-left text-2xl md:text-4xl m-5 font-bold">
            Tailored Wellness Plans
          </h1>
          <p className="text-gray-600 text-left text-base md:text-lg">
            StuFit provides comprehensive resources and strategies to support children’s health, ensuring they thrive both academically and physically.
          </p>
        </div>
        <div className="w-full md:w-1/3 p-5 text-center">
          <hr className="border-t-2 border-gray-300 mb-4" />
          <h1 className="text-blue-900 text-left text-2xl md:text-4xl m-5 font-bold">
            Enhanced Communication
          </h1>
          <p className="text-gray-600 text-left text-base md:text-lg">
            We streamline communication between parents, schools, and health professionals, ensuring everyone is informed and involved in the child’s health journey.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HealthBenefits;
