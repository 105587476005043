import React from 'react';

const ChildrenHealth = () => {
  return (
    <div className="bg-white px-12 py-16">
      <h2 className="text-5xl font-bold text-center text-gray-800 mb-8">Reimagining Children’s Health</h2>
      <p className="text-xl text-center text-gray-600 mb-12">
        We are redefining the future of wellness by creating innovative solutions that empower every child to thrive physically, mentally & emotionally.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
        <div className="text-center">
          <div className="border-t-4 border-gray-400 mb-6"></div>
          <h3 className="text-3xl font-semibold text-gray-800 mb-4">Empowering Through Education</h3>
          <p className="text-lg text-gray-600">
            We prioritize equipping children and families with knowledge and resources, fostering a proactive approach to health that encourages lifelong wellness habits.
          </p>
        </div>
        <div className="text-center">
          <div className="border-t-4 border-gray-400 mb-6"></div>
          <h3 className="text-3xl font-semibold text-gray-800 mb-4">Community-Centric Solutions</h3>
          <p className="text-lg text-gray-600">
            By collaborating with local organizations and schools, we ensure that our programs are culturally relevant and tailored to meet the specific needs of each community we serve.
          </p>
        </div>
        <div className="text-center">
          <div className="border-t-4 border-gray-400 mb-6"></div>
          <h3 className="text-3xl font-semibold text-gray-800 mb-4">Data-Driven Insights</h3>
          <p className="text-lg text-gray-600">
            Utilizing advanced analytics and health assessments, we provide personalized health plans that are informed by real-time data, enabling targeted interventions for optimal outcomes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChildrenHealth;
