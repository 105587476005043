import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import aboutvideo from '../assets/AboutLucknow.mp4';
import imga from '../assets/imga.jpg';
import imgb from '../assets/imgb.jpg';
import '../components/HeroSection.css';
import CEO from '../components/CEO';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import logo from '../assets/logo.png';

const AboutPage = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation(); // Get the current location

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const isActive = (path) => location.pathname === path; // Check if the path matches

  return (
    <div className="bg-light-blue-100">
      {/* Navbar for small screens */}
      <div className="lg:hidden z-0">
        <Navbar />
      </div>

      {/* Video Section */}
      <div className="relative h-[300px] sm:h-[400px] overflow-hidden">
        <video autoPlay loop muted className="w-full h-full object-cover">
          <source src={aboutvideo} type="video/mp4" />
        </video>
        <div className="absolute inset-0 bg-black opacity-70"></div>
        <div className="hidden md:block justify-evenly">
          <img
            src={logo}
            alt="StuFit"
            className="logo w-16 md:w-20" // Smaller logo on mobile
          />
          <nav className="nav-links hidden md:flex space-x-4 text-white">
          <Link to="/" className="brand-name text-white font-bold ml-7">
  StuFit
</Link>
            <Link
              to="/"
              className={`hover:text-blue-400 ${isActive('/') ? 'text-blue-400 font-bold' : ''}`}
            >
              HOME
            </Link>
            <Link
              to="/about"
              className={`hover:text-blue-400 ${isActive('/about') ? 'text-blue-400 font-bold' : ''}`}
            >
              ABOUT
            </Link>
            <Link
              to="/services"
              className={`hover:text-blue-400 ${isActive('/services') ? 'text-blue-400 font-bold' : ''}`}
            >
              SERVICES
            </Link>
            <Link
              to="/contact"
              className={`hover:text-blue-400 ${isActive('/contact') ? 'text-blue-400 font-bold' : ''}`}
            >
              CONTACT
            </Link>
          </nav>
        </div>
        <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl sm:text-5xl font-bold text-white z-10 shadow-xl p-3">
          About Us
        </h1>
      </div>

      <div className="bg-gradient-to-br from-blue-200 via-white to-blue-200">
        {/* Journey Section */}
        <section className="py-12 px-6 md:px-20 text-center">
          <h2 className="text-3xl sm:text-5xl font-semibold text-gray-800 mb-4">
            Our Journey Towards Healthier Futures
          </h2>
          <p className="text-xl sm:text-2xl text-gray-600 max-w-3xl mx-auto">
            At StuFit, we are committed to improving children's health through our innovative Digital Health Report Card.
            Our goal is to provide parents and educators with comprehensive insights to support a healthy and balanced
            lifestyle for young individuals, ensuring a healthier future.
          </p>
        </section>

        {/* Mission and Core Values Section */}
        <section className="py-12 px-6 md:px-20 bg-light-blue-50 space-y-12">
          {/* Mission Section */}
          <div className="md:flex md:items-start md:space-x-8">
            <div className="w-full md:w-1/2 h-auto overflow-hidden rounded-lg shadow-md transition-transform duration-300 hover:scale-105 hover:shadow-xl">
              <img src={imga} alt="Our Mission" className="w-full h-full object-cover" />
            </div>
            <div className="md:w-1/2 space-y-4 mt-6 md:mt-0">
              <h3 className="text-3xl sm:text-5xl font-bold text-gray-800">Our Mission</h3>
              <p className="text-gray-600 text-lg sm:text-xl">
                Our mission is to empower students’ health through early education and proactive assessments. By partnering
                with schools and parents, we aim to create an environment that nurtures growth, resilience, and well-being,
                paving the way for a brighter, healthier future.
              </p>
            </div>
          </div>
          <hr />

          {/* Core Values Section */}
          <div className="md:flex md:flex-row-reverse md:items-start md:space-x-8 md:space-x-reverse">
            <div className="w-full md:w-1/2 h-auto overflow-hidden rounded-lg shadow-md transition-transform duration-300 hover:scale-105 hover:shadow-xl mx-auto">
              <img src={imgb} alt="Core Values" className="w-full h-full object-cover" />
            </div>
            <div className="mt-6 md:mt-0 md:w-1/2 space-y-4">
              <h3 className="text-3xl sm:text-5xl font-bold text-gray-800">Core Values of StuFit</h3>
              <ul className="text-gray-600 list-disc list-inside space-y-2 text-lg sm:text-xl">
                <li><strong>Compassionate Care:</strong> We prioritize empathy in every interaction, especially in supporting children's health.</li>
                <li><strong>Innovation:</strong> We continuously explore new methods to improve health monitoring and education.</li>
                <li><strong>Accountability:</strong> We are dedicated to being responsible and transparent in all our actions.</li>
                <li><strong>Community:</strong> We foster an inclusive community that values well-being and collaborative growth.</li>
              </ul>
            </div>
          </div>
        </section>
        <hr />
      </div>

      <section className="py-20 px-6 md:px-20">
        <h2 className="text-3xl sm:text-5xl font-bold text-gray-800 mb-6 text-center">Our Holistic Approach to Health</h2>
        <p className="text-xl sm:text-2xl text-gray-900 mb-12 text-center">
          At StuFit, we believe in a comprehensive approach to health that includes assessment, intervention, and monitoring.
          This approach allows us to tailor our methods to meet the unique needs of every child.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div>
            <h3 className="text-2xl sm:text-3xl font-semibold text-gray-800 mb-2">Assessment</h3>
            <p className="text-lg text-gray-700">Comprehensive assessments to identify each child's health strengths and areas for improvement.</p>
          </div>
          <div>
            <h3 className="text-2xl sm:text-3xl font-semibold text-gray-800 mb-2">Intervention</h3>
            <p className="text-lg text-gray-700">Targeted interventions designed to promote balanced development and well-being.</p>
          </div>
          <div>
            <h3 className="text-2xl sm:text-3xl font-semibold text-gray-800 mb-2">Monitoring</h3>
            <p className="text-lg text-gray-700">Continuous monitoring and adjustments to support each child's ongoing health journey.</p>
          </div>
        </div>
      </section>

      <div className="m-0">
        <CEO />
      </div>
      <CallToAction />
      <Footer />
    </div>
  );
};

export default AboutPage;
