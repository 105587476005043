import React from 'react';
import HeroSection from '../components/HeroSection';
import ChallengesSection from '../components/ChallengesSection';
import Cards from '../components/Cards';
import HowFar from '../components/HowFar';
import HealthBenefits from '../components/HealthBenefits';
import Testimonials from '../components/Testimonials';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import NavLarge from '../components/NavLarge';
import Navbar from '../components/Navbar';
import Navbarsmall from '../components/Navbarsmall';

const Home = () => {
  return (
    <>
      {/* Navigation for small screens */}
      <div className="lg:hidden">
        <Navbarsmall />
      </div>

      {/* Navigation for larger screens */}
      <div className="hidden lg:block">
        <NavLarge />
      </div>

      {/* Hero Section */}
      <div className="hidden md:block">
        <HeroSection />
      </div>

      {/* Content Sections */}
      <div className="space-y-8 md:space-y-16">
        
        <ChallengesSection />
        
        <Cards />
        
        <HealthBenefits />
        
        <HowFar />
        
        <Testimonials />
        
        <CallToAction />
        
        <Footer />
      </div>
    </>
  );
};

export default Home;
