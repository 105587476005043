import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaHome, FaInfoCircle, FaConciergeBell, FaEnvelope } from 'react-icons/fa';
import log from '../assets/logo.png';

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div className="relative z-20">
      <div className="p-4 flex items-center justify-between bg-black text-white relative">
        {/* Logo at the top right with smaller size */}
        <img
          src={log}
          alt="StuFit"
          className="absolute top-2 right-0 w-12 mt-2 mr-2 z-10"  // Adjusted size here to w-12
        />

        {/* Title / Brand Name - keeping it on a single line */}
        <Link to="/" className="brand-name text-white font-bold ml-7 hidden sm:block">
          StuFit
        </Link>

        {/* Desktop Navigation Links */}
        <nav className="hidden md:flex space-x-4">
          <Link to="/" className="flex items-center space-x-2">
            <FaHome />
            <span>HOME</span>
          </Link>
          <Link to="/about" className="flex items-center space-x-2">
            <FaInfoCircle />
            <span>ABOUT</span>
          </Link>
          <Link to="/services" className="flex items-center space-x-2">
            <FaConciergeBell />
            <span>SERVICES</span>
          </Link>
          <Link to="/contact" className="flex items-center space-x-2">
            <FaEnvelope />
            <span>CONTACT</span>
          </Link>
        </nav>

        {/* Hamburger Icon for Mobile */}
        <div className="md:hidden cursor-pointer" onClick={() => setIsSidebarOpen(true)}>
          <FaBars size={24} />
        </div>
      </div>

      {/* Sidebar for Mobile */}
      {isSidebarOpen && (
        <div className="fixed top-0 left-0 h-full w-64 bg-blue-900 text-white transform translate-x-0 transition-transform duration-300 ease-in-out z-30">
          <div className="p-4 flex justify-end">
            <FaTimes size={24} className="cursor-pointer" onClick={() => setIsSidebarOpen(false)} />
          </div>
          <nav className="flex flex-col space-y-6 p-6">
            <Link to="/" onClick={() => setIsSidebarOpen(false)} className="flex items-center space-x-2">
              <FaHome />
              <span>HOME</span>
            </Link>
            <Link to="/about" onClick={() => setIsSidebarOpen(false)} className="flex items-center space-x-2">
              <FaInfoCircle />
              <span>ABOUT</span>
            </Link>
            <Link to="/services" onClick={() => setIsSidebarOpen(false)} className="flex items-center space-x-2">
              <FaConciergeBell />
              <span>SERVICES</span>
            </Link>
            <Link to="/contact" onClick={() => setIsSidebarOpen(false)} className="flex items-center space-x-2">
              <FaEnvelope />
              <span>CONTACT</span>
            </Link>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Navbar;
