import React from 'react';
import Teeth from '../assets/oralhealth.jpg'; // Replace with correct image path
import Navbar from '../components/Navbar'; // Navbar for mobile view
import { Link } from 'react-router-dom';
import '../Needed/Eye.css';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import logo from '../assets/logo.png';

const OralHealth = () => {
  return (
    <>
      <div className="text-white bg-gray-900 w-full">
        {/* Mobile Navbar */}
        <div className="lg:hidden z-0">
          <Navbar />
        </div>

        {/* Desktop Navbar */}
        <div className="hidden md:block justify-evenly px-8 py-8 mx-auto">
          <img
            src={logo}
            alt="StuFit"
            className="logo"
          />
          <nav className="nav-links hidden md:flex space-x-4 text-white ml-10 mr-10">
            <span className="brand-name text-white font-bold mr-11">StuFit</span>
            <Link to="/" className="hover:text-blue-400">HOME</Link>
            <Link to="/about" className="hover:text-blue-400">ABOUT</Link>
            <Link to="/services" className="hover:text-blue-400">SERVICES</Link>
            <Link to="/contact" className="hover:text-blue-400">CONTACT</Link>
          </nav>
        </div>

        <div className="py-6 px-4 sm:px-10">
          <header className="header text-center mb-8 mt-20">
            <h1 className="text-4xl sm:text-5xl font-bold mt-15">
              Essential Oral Health Tips for Primary School Children 
            </h1>
            <h1 className='className="text-4xl sm:text-5xl font-bold mt-15'>(Ages 5-12)</h1>
            <img
              src={Teeth}
              alt="Child brushing teeth"
              className="header-image mx-auto mt-4 w-full sm:w-4/5 h-auto object-cover"
            />
            <p className="caption mt-2 text-lg sm:text-xl text-gray-300">
              Teaching good oral hygiene habits to children aged 5-12 is crucial for their long-term dental health. 
              Here’s how to ensure your child develops a healthy smile and avoids common dental problems.
            </p>
          </header>

          <main>
            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">Why Oral Health Matters</h2>
              <p className="mt-2 text-lg sm:text-xl text-gray-300">
                Oral health is directly linked to a child’s overall well-being. Proper dental care prevents cavities,
                gum disease, and tooth decay, which can cause pain, affect eating habits, and impact a child’s ability
                to focus in school. Establishing good habits early ensures a lifetime of healthy smiles.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">Common Dental Problems in Children</h2>
              <ul className="list-disc list-inside mt-2 text-lg sm:text-xl text-gray-300">
                <li>
                  <strong>Cavities:</strong> Caused by plaque buildup and sugar consumption, leading to tooth decay.
                </li>
                <li>
                  <strong>Gum Inflammation:</strong> Poor oral hygiene can cause gingivitis, leading to red or swollen gums.
                </li>
                <li>
                  <strong>Tooth Sensitivity:</strong> Sensitivity to hot or cold foods due to enamel erosion.
                </li>
                <li>
                  <strong>Teeth Grinding (Bruxism):</strong> Often stress-related, this can wear down teeth over time.
                </li>
                <li>
                  <strong>Misaligned Teeth:</strong> Issues with spacing or crowding that may require orthodontic care.
                </li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">Healthy Habits for Children</h2>
              <ul className="list-disc list-inside mt-2 text-lg sm:text-xl text-gray-300">
                <li>
                  <strong>Brush Twice a Day:</strong> Teach your child to brush their teeth in the morning and before
                  bedtime using fluoride toothpaste.
                </li>
                <li>
                  <strong>Floss Daily:</strong> Introduce flossing to remove food particles and plaque between teeth.
                </li>
                <li>
                  <strong>Limit Sugary Foods:</strong> Reduce the consumption of sweets, soda, and sticky snacks that
                  promote cavities.
                </li>
                <li>
                  <strong>Drink Water:</strong> Encourage drinking water instead of sugary drinks to keep teeth clean.
                </li>
                <li>
                  <strong>Use a Mouthguard:</strong> Protect teeth during sports activities to prevent injuries.
                </li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">Signs Your Child May Need a Dentist Visit</h2>
              <ul className="list-disc list-inside mt-2 text-lg sm:text-xl text-gray-300">
                <li>Persistent tooth pain or sensitivity</li>
                <li>Bleeding gums while brushing or flossing</li>
                <li>Difficulty chewing or biting</li>
                <li>White or dark spots on teeth</li>
                <li>Loose teeth outside of the normal shedding process</li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">Regular Dental Check-Ups</h2>
              <p className="mt-2 text-lg sm:text-xl text-gray-300">
                Scheduling a dental check-up every six months ensures early detection of problems and professional
                cleaning to maintain healthy teeth. Dentists can also provide guidance on fluoride treatments and
                sealants to prevent decay.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">Preventing Future Dental Issues</h2>
              <p className="mt-2 text-lg sm:text-xl text-gray-300">
                Instilling good oral hygiene habits at a young age sets the foundation for lifelong dental health. Teach
                your child proper brushing techniques and encourage regular dentist visits for preventive care.
              </p>
            </section>

            <footer className="mt-8">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">Conclusion</h2>
              <p className="mt-2 text-lg sm:text-xl text-gray-300">
                Maintaining oral health during primary school years is essential for healthy development. By encouraging
                proper hygiene, regular dental visits, and a balanced diet, you can help your child build a strong
                foundation for a lifetime of healthy teeth and gums.
              </p>
            </footer>
          </main>
        </div>
        <CallToAction/>
        <Footer/>
      </div>
    </>
  );
};

export default OralHealth;
