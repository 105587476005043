import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-blue-50 text-blue-900 py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between text-sm">
          {/* About Us */}
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="font-bold text-lg mb-2">About Us</h3>
            <p>Stufit is dedicated to enhancing children's health through innovation and collaboration with schools.</p>
            <div className="flex space-x-4 mt-4">
              <a href="https://www.facebook.com/stufit.in/" target="_blank" rel="noopener noreferrer">
                <FaFacebook className="text-blue-600 cursor-pointer" />
              </a>
              <a href="https://x.com/Stufit11" target="_blank" rel="noopener noreferrer">
                <FaTwitter className="text-blue-400 cursor-pointer" />
              </a>
              <a href="https://www.instagram.com/stufit.in/" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="text-pink-500 cursor-pointer" />
              </a>
              <a href="https://in.linkedin.com/company/stufit-approach-private-limited" target="_blank" rel="noopener noreferrer">
                <FaLinkedin className="text-blue-700 cursor-pointer" />
              </a>
              <a href="https://www.youtube.com/@stufitlife/videos?themeRefresh=1" target="_blank" rel="noopener noreferrer">
                <FaYoutube className="text-red-600 cursor-pointer" />
              </a>
            </div>
          </div>

          {/* Useful Links */}
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="font-bold text-lg mb-2">Useful Links</h3>
            <ul className="space-y-2">
              <li><Link to="/about" className="hover:underline">About</Link></li>
              <li><Link to="/services" className="hover:underline">Services</Link></li>
              <li><Link to="/contact" className="hover:underline">Contact</Link></li>
            </ul>
          </div>

          {/* Contact Us */}
          <div className="w-full md:w-1/3">
            <h3 className="font-bold text-lg mb-2">Contact Us</h3>
            <p>B1, Paramount Plaza, New Berry Road, Lucknow, U.P. (India)</p>
            <p>info@stufit.in</p>
          </div>
        </div>

        <div className="text-center mt-8 text-xs text-blue-900">
          <hr />
          Copyright © 2024 StuFit
        </div>
      </div>
    </footer>
  );
};

export default Footer;
