import React, { useState } from 'react';
import * as emailjs from 'emailjs-com';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [last, setLast] = useState('');
  const [number, setNumber] = useState('');
  const [errors, setErrors] = useState({});

  const sendEmail = () => {
    // Simple form validation
    if (!name || !email || !message) {
      alert('Please fill out all required fields.');
      return;
    }

    const data = {
      from_name: `${name} ${last}`,
      email,
      message,
      phone_number: number,
    };

    const service_id = 'service_crz9r6h';  // Your EmailJS service ID
    const template_id = 'template_husc3hi'; // Your EmailJS template ID
    const user_id = 'IoVn5CL3-MeLb2T61'; // Your EmailJS user ID

    // Send email using EmailJS
    emailjs.send(service_id, template_id, data, user_id)
      .then(
        () => {
          alert('Message sent successfully!');
          setName('');
          setEmail('');
          setMessage('');
          setLast('');
          setNumber('');
          setErrors({});
        },
        () => alert('Error in sending message.')
      );
  };

  return (
    <div className="bg-blue-50 py-12">
      <div className="max-w-6xl mx-auto px-2">
        <div className="w-full md:w-1/2 mx-auto">
          <h1 className="text-5xl font-bold text-blue-900 mb-6">Reach Out to Us</h1>
          <p className="text-lg text-gray-700 mb-6">
            We'd love to hear from you! For inquiries or feedback, please reach out. Our team is here to assist you with any questions.
          </p>

          <form className="space-y-5">
            {/* First Name */}
            <div>
              <label htmlFor="firstName" className="block text-lg font-medium text-gray-700">First Name</label>
              <input 
                type="text" 
                id="firstName" 
                value={name} 
                onChange={(e) => setName(e.target.value)} 
                className="w-full p-3 border border-gray-300 rounded-md" 
                placeholder="Enter your first name"
              />
            </div>

            {/* Last Name */}
            <div>
              <label htmlFor="lastName" className="block text-lg font-medium text-gray-700">Last Name</label>
              <input 
                type="text" 
                id="lastName" 
                value={last} 
                onChange={(e) => setLast(e.target.value)} 
                className="w-full p-3 border border-gray-300 rounded-md" 
                placeholder="Enter your last name"
              />
            </div>

            {/* Email */}
            <div>
              <label htmlFor="email" className="block text-lg font-medium text-gray-700">Email</label>
              <input 
                type="email" 
                id="email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                className="w-full p-3 border border-gray-300 rounded-md" 
                placeholder="Enter your email"
              />
            </div>

            {/* Phone Number */}
            <div>
              <label htmlFor="phone" className="block text-lg font-medium text-gray-700">Phone Number</label>
              <input 
                type="text" 
                id="phone" 
                value={number} 
                onChange={(e) => setNumber(e.target.value)} 
                className="w-full p-3 border border-gray-300 rounded-md" 
                placeholder="Enter your phone number"
              />
            </div>

            {/* Message */}
            <div>
              <label htmlFor="message" className="block text-lg font-medium text-gray-700">Message</label>
              <textarea 
                id="message" 
                value={message} 
                onChange={(e) => setMessage(e.target.value)} 
                className="w-full p-3 border border-gray-300 rounded-md" 
                placeholder="Enter your message"
              ></textarea>
            </div>

            {/* Submit Button */}
            <button
              type="button"
              className="w-full bg-blue-800 text-white py-3 rounded font-semibold hover:bg-blue-700"
              onClick={sendEmail}
            >
              Submit
            </button>
          </form>
        </div>

        {/* Map Section */}
        <div className="mt-12">
          <h2 className="text-3xl font-bold text-blue-900 mb-6">Our Location</h2>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.595672614421!2d80.95585937543743!3d26.85280957668292!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfd1d4438bcdf%3A0xe32444386d62c4a2!2sSTUFIT%20APPROACH%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1732607367838!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
