import React from 'react';
import img1 from '../assets/img1.png';
import img2 from '../assets/img2.png';
import img3 from '../assets/img3.png';

const Cards = () => {
  return (
    <div className='bg-blue-50'>
      <div className="flex flex-col items-center px-6 py-10 mx-10">
        
        <h1 className="text-6xl font-bold text-center text-blue-900 mb-2 mt-10">What We Do</h1>
        
        <p className="text-center max-w-2xl text-gray-900 mb-2">
          StuFit offers a variety of services designed to tackle the challenges associated with children’s health, ensuring every child has access to the care they deserve.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 my-10">
          
          <div className="flex flex-col items-center m-10 ">
            <div className="w-full h-64 overflow-hidden rounded-lg border-4 border-gray-200 mb-4">
              <img 
                src={img1} 
                alt="Service 1" 
                className="w-full h-full object-cover transform transition duration-300 ease-in-out hover:scale-110"
              />
            </div>
            <h2 className="text-2xl font-semibold text-center text-blue-900">Health Assessments</h2>
            <p className="text-center text-gray-700 mt-2">
              Our Digital Health Report Cards deliver comprehensive assessments of children’s health, equipping parents and educators with insights for early detection of potential health issues, fostering a healthier future for every child.
            </p>
          </div>
          
          <div className="flex flex-col items-center m-10">
            <div className="w-full h-64 overflow-hidden rounded-lg border-4 border-gray-200 mb-4">
              <img 
                src={img2} 
                alt="Service 2" 
                className="w-full h-full object-cover transform transition duration-300 ease-in-out hover:scale-110"
              />
            </div>
            <h2 className="text-2xl font-semibold text-center text-blue-900">Interactive Workshops</h2>
            <p className="text-center text-gray-700 mt-2">
              We offer interactive health workshops focused on children’s well-being. These workshops engage students, parents, and staff alike. Together, we promote healthier lifestyles and awareness.
            </p>
          </div>
          
          <div className="flex flex-col items-center m-10">
            <div className="w-full h-64 overflow-hidden rounded-lg border-4 border-gray-200 mb-4">
              <img 
                src={img3} 
                alt="Service 3" 
                className="w-full h-full object-cover transform transition duration-300 ease-in-out hover:scale-110"
              />
            </div>
            <h2 className="text-2xl font-semibold text-center text-blue-900">Mental Health Support</h2>
            <p className="text-center text-gray-700 mt-2">
              Our wellness screenings help identify health concerns early, ensuring timely support for children’s mental and overall well-being. This proactive approach promotes healthier development and long-term success.
            </p>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Cards;
