import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import vid from '../assets/backgroundvideo.mp4';
import log from '../assets/logo.png';
import Navbar from './Navbar';

import './HeroSection.css';



const Navbarsmall = () => {
  const heroTextRef = useRef();

  useEffect(() => {
    gsap.fromTo(
      heroTextRef.current,
      { y: -100, opacity: 0 },
      { y: 0, opacity: 1, duration: 2, ease: 'power3.out' }
    );
  }, []);

  return ( <>
      <div>
        <Navbar/>
      </div>
    <div className="hero-section">
      <video autoPlay loop muted playsInline className="bg-video">
        <source src={vid} type="video/mp4" />
      </video>
      
      <div className="top hidden md:block">
        <img src={log} alt="StuFit" className="logo" />
        
        {/* Centered StuFit Title */}
      
      
      </div>
     
      
      <div className="hero-text" ref={heroTextRef}>
        <h1>Is Your Child’s Health Being <span className="highlight">Monitored?</span></h1>
        <h2><span className='mew'>Get a</span> <span className="dhrc">DHRC!</span></h2>
      </div>
      <p className='report-card'>Digital Health Report Card</p>
    </div> </>
  );
};

export default Navbarsmall;
