import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules'; // Import pagination module

import dramiya from '../assets/dramiya.png';
import drmanju from '../assets/drnew.png';
import drfaiz from '../assets/dr3.png';
import drpriya from '../assets/dr4.png';
import prabhat from '../assets/dr5.png';
import drlakshmi from '../assets/dr6.png';
import './Testi.css';

const testimonials = [
  {
    image: dramiya,
    name: 'Dr. Amiya Agarwal',
    title: 'Asst. Prof. Dental Department KGMU Lucknow',
    comment:
      "Stufit is revolutionizing healthcare by bridging the gap between physical wellness and digital innovation. It's a game-changer in promoting holistic health for all.",
  },
  {
    image: drmanju,
    name: 'Dr. Manju Tripathi',
    title: 'HOD, Pediatric Department - Bliss Hospital, Lucknow',
    comment:
      "With Stufit, individuals and families can easily prioritize their health. It's a brilliant platform that's making wellness more accessible and personalized for everyone.",
  },
  {
    image: drfaiz,
    name: 'Dr. Syed Faiz Hasan',
    title: 'Ram Manohar Lohiya Hospital - Lucknow',
    comment:
      "Stufit offers a refreshing approach to fitness and well-being. Its blend of technology and health expertise is exactly what today's patients need to stay proactive and informed.",
  },
  {
    image: drpriya,
    name: 'Dr. Priyal Ahuja',
    title: 'Founder, Nurture Clinic - Audio & Speech Therapist',
    comment:
      "Stufit seamlessly integrates fitness with daily routines, making health management effortless and enjoyable. It's a valuable asset for anyone striving for a healthier life.",
  },
  {
    image: prabhat,
    name: 'Dr. Prabhat Kanjan',
    title: 'HOD Physiotherapy-Neurology Department - AIIMS New Delhi',
    comment:
      "The ease and accessibility of Stufit make it an outstanding platform for wellness. It's intuitive and incredibly effective in helping users maintain their health goals.",
  },
  {
    image: drlakshmi,
    name: 'Dr. Lekhni Pashiene',
    title: 'PhD Dietetics & Nutrition - KGMU Lucknow',
    comment:
      "Stufit seamlessly integrates fitness with daily routines, making health management effortless and enjoyable, while keeping you motivated with personalized goals and real-time progress tracking.",
  },
];

function Testimonials() {
  // Create a reference to the Swiper component
  const swiperRef = useRef(null);

  return (
    <div className="max-w-6xl mx-auto p-6 relative">
      <h1 className="text-4xl sm:text-5xl md:text-6xl text-blue-950 text-center font-bold mb-10 sm:mb-14">
        Testimonials
      </h1>

      <Swiper
        modules={[Pagination]} // Import Pagination module for dots
        spaceBetween={20}
        slidesPerView={1}
        pagination={{
          clickable: true,
          el: '.custom-pagination', // Custom pagination class
          type: 'bullets',
        }}
        breakpoints={{
          640: { slidesPerView: 1, spaceBetween: 20 },
          768: { slidesPerView: 2, spaceBetween: 30 },
          1024: { slidesPerView: 3, spaceBetween: 40 },
        }}
        className="testimonial-slider"
        ref={swiperRef} // Attach swiperRef to the Swiper component
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className="bg-white rounded-lg p-4 sm:p-6 shadow-lg flex flex-col items-center transition-transform transform hover:scale-105">
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="w-16 h-16 sm:w-20 sm:h-20 rounded-full mb-3 sm:mb-4 border-4 border-blue-500 hover:border-green-500 transition-transform transform hover:scale-110"
              />
              <p className="text-center text-gray-700 mb-3 sm:mb-4 text-sm sm:text-base">
                {testimonial.comment}
              </p>
              <h4 className="text-base sm:text-lg font-semibold text-blue-900">
                {testimonial.name}
              </h4>
              <p className="text-xs sm:text-sm text-gray-900 text-center">
                {testimonial.title}
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Custom Pagination */}
      <div className="custom-pagination flex justify-center mt-4 space-x-2">
        {/* Pagination dots will be automatically inserted here */}
      </div>

      {/* V Shape Arrows */}
      <div className="absolute top-1/2 left-4 transform -translate-y-1/2 z-10">
        <button
          className="arrow-btn-left text-blue-950"
          onClick={() => swiperRef.current.swiper.slidePrev()} // Navigate to previous slide
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30">
            <path d="M15 19l-7-7 7-7" stroke="currentColor" strokeWidth="2" fill="none" />
          </svg>
        </button>
      </div>
      <div className="absolute top-1/2 right-4 transform -translate-y-1/2 z-10">
        <button
          className="arrow-btn-right text-blue-950"
          onClick={() => swiperRef.current.swiper.slideNext()} // Navigate to next slide
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30">
            <path d="M9 5l7 7-7 7" stroke="currentColor" strokeWidth="2" fill="none" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default Testimonials;
