import React from 'react';
import NutritionImage from '../assets/Diet.jpg'; // Replace with the correct path to the nutrition-related image
import { Link } from 'react-router-dom';
import '../Needed/Eye.css'; // Assuming the same CSS is being used
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CallToAction from '../components/CallToAction';
import logo from '../assets/logo.png';

const Nutrition = () => {
  return (
    <>
      <div className="text-white bg-gray-900 w-full">
        <div className="lg:hidden z-0">
          <Navbar />
        </div>
        <div className="hidden md:block justify-evenly px-8 py-8 mx-auto">
          <img
            src={logo}
            alt="StuFit"
            className="logo"
          />
          <nav className="nav-links hidden md:flex space-x-4 text-white ml-10 mr-10">
            <span className="brand-name text-white font-bold mr-11">StuFit</span>
            <Link to="/" className="hover:text-blue-400">HOME</Link>
            <Link to="/about" className="hover:text-blue-400">ABOUT</Link>
            <Link to="/services" className="hover:text-blue-400">SERVICES</Link>
            <Link to="/contact" className="hover:text-blue-400">CONTACT</Link>
          </nav>
        </div>

        <div className="py-6 px-4 sm:px-10">
          <header className="header text-center mb-8 mt-20">
            <h1 className="text-4xl sm:text-5xl font-bold mt-15">Diet & Nutrition Assessment for Children</h1>
            <img
              src={NutritionImage}
              alt="Healthy food for children"
              className="header-image mx-auto mt-4 w-full sm:w-4/5 h-auto object-cover"
            />
            <p className="caption mt-2 text-lg sm:text-xl text-gray-300">
              A balanced diet is critical for children’s growth, academic performance, and overall health. Early
              assessments ensure they are on the right track to a healthy lifestyle.
            </p>
          </header>

          <main>
            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">Why Nutrition is Essential for Children</h2>
              <p className="mt-2 text-lg sm:text-xl text-gray-300">
                Proper nutrition is the foundation of a child’s growth and development. It impacts their energy levels,
                cognitive abilities, immune system, and even emotional well-being. A healthy diet ensures they can excel
                in academics, sports, and social interactions.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">Common Nutritional Concerns</h2>
              <ul className="list-disc list-inside mt-2 text-lg sm:text-xl text-gray-300">
                <li>
                  <strong>Iron Deficiency:</strong> Common among children, leading to fatigue and impaired cognitive
                  development.
                </li>
                <li>
                  <strong>Obesity:</strong> Poor dietary habits and lack of physical activity contribute to weight issues
                  and related health problems.
                </li>
                <li>
                  <strong>Eating Disorders:</strong> Issues like picky eating or over-reliance on processed foods can
                  limit essential nutrient intake.
                </li>
                <li>
                  <strong>Allergies and Intolerances:</strong> Sensitivities to foods like dairy, nuts, or gluten can
                  disrupt balanced nutrition.
                </li>
                <li>
                  <strong>Vitamin Deficiencies:</strong> Lack of key vitamins like Vitamin D or B12 affects growth and
                  immunity.
                </li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">Symptoms of Poor Nutrition</h2>
              <ul className="list-disc list-inside mt-2 text-lg sm:text-xl text-gray-300">
                <li>Poor academic performance or concentration</li>
                <li>Frequent illnesses or slow recovery from infections</li>
                <li>Lack of energy or chronic fatigue</li>
                <li>Delayed growth or underdevelopment</li>
                <li>Dental cavities or unhealthy weight fluctuations</li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">How Diet & Nutrition Assessments Help</h2>
              <p className="mt-2 text-lg sm:text-xl text-gray-300">
                Nutrition assessments evaluate dietary habits, nutrient deficiencies, and overall health. They provide
                personalized recommendations to improve your child’s eating habits, ensuring balanced nutrition tailored to
                their age and activity levels.
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">Tips for Healthy Eating</h2>
              <ul className="list-disc list-inside mt-2 text-lg sm:text-xl text-gray-300">
                <li>Encourage meals rich in fruits, vegetables, whole grains, and lean proteins.</li>
                <li>Limit sugar, salt, and processed food consumption.</li>
                <li>Ensure adequate hydration with plenty of water throughout the day.</li>
                <li>Promote regular meal timings and avoid skipping breakfast.</li>
                <li>Model healthy eating behaviors for your child to follow.</li>
              </ul>
            </section>

            <section className="mb-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">When to Consult a Nutritionist</h2>
              <p className="mt-2 text-lg sm:text-xl text-gray-300">
                If your child experiences persistent health issues, irregular growth patterns, or struggles with eating
                habits, consult a pediatric nutritionist. Early intervention can address potential deficiencies or
                imbalances and guide them toward a healthier lifestyle.
              </p>
            </section>

            <footer className="mt-8">
              <h2 className="text-2xl sm:text-3xl font-semibold text-blue-400">Conclusion</h2>
              <p className="mt-2 text-lg sm:text-xl text-gray-300">
                A nutritious diet fuels your child’s growth, learning, and energy needs. Regular nutrition assessments and
                a focus on healthy eating habits empower children to thrive both physically and mentally.
              </p>
            </footer>
          </main>
        </div>

        <CallToAction />
        <Footer />
      </div>
    </>
  );
};

export default Nutrition;
