import React from 'react';
import { Link } from 'react-router-dom';
import './HeroSection.css';

const NavLarge = () => {
  return (
    <div>
      <div className="hidden md:block">
        <nav className="nav-links hidden md:flex space-x-4 text-white">
          {/* Added margin to shift the span to the right */}
       {/* Title / Brand Name - keeping it on a single line */}
       <Link to="/" className="brand-name text-white font-bold ml-7 sm:hidden">
  StuFit
</Link>
          <Link to="/" className="nav-link">HOME</Link>
          <Link to="/about" className="nav-link">ABOUT</Link>
          <Link to="/services" className="nav-link">SERVICES</Link>
          <Link to="/contact" className="nav-link">CONTACT</Link>
        </nav>
      </div>
    </div>
  );
};

export default NavLarge;
