import React from 'react';
import { useNavigate } from 'react-router-dom';
import img4 from '../assets/img4.png';

const HowFar = () => {
  const navigate = useNavigate(); // Hook to navigate programmatically

  const handleReadMore = () => {
    navigate('/services'); // Navigate to the Services page
  };

  return (
    <div className="py-16 bg-blue-50">
      <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
        <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
          
          {/* Image Section */}
          <div className="md:w-5/12 lg:w-5/12">
            <img src={img4} alt="image" loading="lazy" width="" height="900" />
          </div>
          
          {/* Text Section */}
          <div className="md:w-7/12 lg:w-6/12 relative flex flex-col h-full">
            <h2 className="text-2xl text-blue-900 font-bold md:text-4xl">How Far We Have Come</h2>
            <p className="mt-6 text-gray-700">
              StuFit started with a vision to revolutionize children’s healthcare using technology. We have made tremendous progress in championing early detection and student wellness on both international and global scales.
            </p>
            <p className="mt-4 text-gray-600">
              From India to Tanzania, and across various countries, our comprehensive health programs have been instrumental in identifying potential concerns at an early stage. By working hand-in-hand with educators and families in diverse cultural contexts, we deliver tailored wellness strategies that promote holistic development. Our international reach demonstrates our commitment to improving children’s health worldwide, empowering students to thrive no matter where they are, and creating a lasting impact on global student wellness.
            </p>
            
            {/* Button in Bottom-Right */}
            <div className="mt-auto flex justify-end">
              <button
                onClick={handleReadMore} // Attach the navigation function
                className="bg-transparent hover:bg-blue-500 mt-6 text-gray-700 font-semibold hover:text-white py-2 px-4 border border-gray-700 hover:border-transparent rounded"
              >
                Read More
              </button>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default HowFar;
