import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './ChallengesSection.css';
import '../assets/logo.png'

gsap.registerPlugin(ScrollTrigger);

const ChallengesSection = () => {
  const cardRefs = useRef([]);

  useEffect(() => {
    // GSAP Animation for each card on scroll
    cardRefs.current.forEach((card, index) => {
      gsap.fromTo(
        card,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          delay: index * 0.2,
          ease: 'power3.out',
          scrollTrigger: {
            trigger: card,
            start: 'top 80%',
          },
        }
      );
    });
  }, []);

  return (
    <section className="challenges-section">
      <div className="lead">
        <h2>Challenges in Addressing Student Health?</h2>
        <p className="description">
          Understanding the challenges surrounding children’s health is crucial. Our mission is to provide solutions for common issues parents face today.
        </p>
      </div>

      <div className="cards-container">
        {[
          {
            title: 'Limited Awareness',
            description: 'Many parents are unaware of their children’s health status and potential issues. This lack of information can delay necessary treatments, putting children’s well-being at risk.',
            icon: '🤔',
          },
          {
            title: 'Insufficient Resources',
            description: 'Access to adequate health resources in schools remains a challenge. Many institutions struggle to provide proper health support.',
            icon: '🏥',
          },
          {
            title: 'Delayed Intervention',
            description: 'Early detection is key to managing health conditions effectively. Parents may find it difficult to act quickly when vital health signals are missed.',
            icon: '⏱️',
          },
          {
            title: 'Health Affects Learning',
            description: 'Students’ physical and mental health significantly impact their academic success, making it crucial to prioritize well-being for better engagement and achievement.',
            icon: '📄',
          },
        ].map((item, index) => (
          <div className="card" key={index} ref={(el) => (cardRefs.current[index] = el)}>
            <div className="icon">{item.icon}</div>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ChallengesSection;
