import React from 'react';
import { useNavigate } from 'react-router-dom';
import img1 from '../assets/img11.jpg';
import img2 from '../assets/img22.jpg';
import img3 from '../assets/img33.jpg';
import img4 from '../assets/img44.jpg';
import img5 from '../assets/img88.jpg';
import img6 from '../assets/img99.jpg';

const Compo = () => {
  const navigate = useNavigate();

  const services = [
    {
      id: "01",
      title: "Vision Assessment",
      description:
        "A child’s ability to succeed in school depends on several key skills, with good vision being one of the most critical. From reading and writing to using a computer and viewing the chalkboard, students rely on their eyes for nearly every task throughout the day. When a child’s vision isn’t functioning properly, it can significantly hinder academic performance and participation in sports and playtime activities.",
      imgSrc: img1,
      link: '/eye',
    },
    {
      id: "02",
      title: "Dental Assessment",
      description:
        "Wondering how to brush your child’s teeth or how often they need a dental check-up? Regular dental care is key to preventing tooth decay and promoting good oral health in school children.",
      imgSrc: img2,
      link: '/OralHealth',
    },
    {
      id: "03",
      title: "Audio Assessment",
      description:
        "Hearing plays a vital role in a child’s social, emotional, and cognitive growth, especially in the early years. Even mild hearing loss can impact speech and language development, highlighting the need for proper auditory health.",
      imgSrc: img3,
      link: '/HearingTests',
    },
    {
      id: "04",
      title: "Spine & Posture Assessment",
      description:
        "Good posture is crucial for children, even in preschool, as they undergo significant physical and mental growth before age ten. Learning proper posture early helps them maintain it during their school years, where extended sitting becomes more common.",
      imgSrc: img4,
      link: '/spine',
    },
    {
      id: "05",
      title: "Diet & Nutrition Assessment",
      description:
        "What your child eats today greatly influences their health into adolescence and adulthood. Nutritious foods support growth, academic performance, and self-esteem while preventing issues like obesity, eating disorders, dental cavities, and iron-deficiency anaemia.",
      imgSrc: img5,
      link: '/nutrition',
    },
    {
      id: "06",
      title: "Psychological Health Assessment",
      description:
        "Psychological health is vital to a child's emotional and mental well-being. Regular assessments help identify early signs of stress, anxiety, or other emotional challenges, ensuring children develop healthy coping strategies and resilience.",
      imgSrc: img6,
      link: '/PsychologicalSocial',
    },
  ];

  return (
    <div className="bg-blue-50 py-16 px-6 md:px-20 space-y-16">
      {services.map((service, index) => (
        <div
          key={index}
          className={`flex flex-col md:flex-row items-center ${index % 2 === 0 ? "gap-12" : "gap-12 md:flex-row-reverse"}`}
        >
          {/* Text Section */}
          <div className="md:w-1/2 px-6 py-12 flex flex-col justify-center">
            <div className="text-3xl md:text-4xl font-bold text-blue-600">{service.id}</div>
            <h3 className="text-4xl md:text-5xl font-extrabold mt-4 leading-tight text-gray-900">
              {service.title}
            </h3>
            <p className="text-lg md:text-xl mt-6 text-gray-800 leading-relaxed">
              {service.description}
            </p>
            <button
              onClick={() => navigate(service.link)}
              className="text-lg md:text-xl text-blue-700 font-bold mt-6 text-left"
            >
              Why it is needed?
            </button>
          </div>

          {/* Image Section */}
          <div className="md:w-1/2 px-6 py-12 flex justify-center">
            <img
              src={service.imgSrc}
              alt={service.title}
              className="w-full max-w-2xl rounded-lg shadow-lg"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Compo;
