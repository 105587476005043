import React from 'react';
import ceoImage from '../assets/CEO.jpeg'; 
import directorImage from '../assets/DIRECTOR.png'; 
import DS from '../assets/DS.jpg';
import COO from '../assets/COO.jpg';

const CEO = () => {
  return (
    <section className="py-12 px-6 mb-12 md:px-20 bg-light-blue-100">
      <h2 className="text-5xl font-semibold text-blue-900 text-center mb-12">
        The Driving Force Behind Our Success And Growth
      </h2>

      {/* Card Container */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
        
        {/* CEO Card */}
        <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-center h-[34rem] w-full transition-transform transform hover:scale-105 hover:shadow-2xl">
          <img
            src={ceoImage}
            alt="CEO"
            className="w-48 h-48 rounded-full border-4 border-gray-300 object-cover shadow-md mb-4 transition-transform transform hover:scale-110 hover:border-blue-500"
          />
          <h3 className="text-xl font-bold text-blue-800 mb-1">Dr. Syed Shujat Haider Jafri</h3>
          <p className="text-gray-500 text-xs mb-2">Founder and CEO</p>
          <p className="text-gray-600 text-sm max-w-full">
          Dr. Syed Shujat Haider Jafri, CEO and Founder of StuFit, drives innovation in early health detection for students. Committed to student well-being, his leadership ensures proactive health solutions, promoting physical and mental wellness within healthier educational environments.
          </p>
        </div>

        {/* Director Card */}
        <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-center h-[34rem] w-full transition-transform transform hover:scale-105 hover:shadow-2xl">
          <img
            src={directorImage}
            alt="Director"
            className="w-48 h-48 rounded-full border-4 border-gray-300 object-cover shadow-md mb-4 transition-transform transform hover:scale-110 hover:border-blue-500"
          />
          <h3 className="text-xl font-bold text-blue-800 mb-1">Dr. Sabihka Abidi</h3>
          <p className="text-gray-500 text-xs mb-2">Director</p>
          <p className="text-gray-600 text-sm max-w-full">
          Dr. Sabihka Abidi, Director at StuFit, combines expertise in education and leadership to shape strategy, enhance teaching innovation, and foster collaboration. Her dedication ensures exceptional learning experiences, empowering students and staff while driving StuFit's mission for educational excellence.
          </p>
        </div>

        {/* COO Card */}
        <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-center h-[34rem] w-full transition-transform transform hover:scale-105 hover:shadow-2xl">
          <img
            src={COO}
            alt="COO"
            className="w-48 h-48 rounded-full border-4 border-gray-300 object-cover shadow-md mb-4 transition-transform transform hover:scale-110 hover:border-blue-500"
          />
          <h3 className="text-xl font-bold text-blue-800 mb-1">Satyam Gupta</h3>
          <p className="text-gray-500 text-xs mb-2">Chief Operating Officer (COO)</p>
          <p className="text-gray-600 text-sm max-w-full">
          Satyam Gupta, COO at StuFit, excels in operations management and strategic leadership. His visionary approach enhances efficiency and innovation, driving operational excellence. Committed to collaboration and continuous improvement, Satyam shapes StuFit’s future, ensuring exceptional value and sustained success.
          </p>
        </div>

        {/* AI Research Scientist Card */}
        <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-center h-[34rem] w-full transition-transform transform hover:scale-105 hover:shadow-2xl">
          <img
            src={DS}
            alt="AI Research Scientist"
            className="w-48 h-48 rounded-full border-4 border-gray-300 object-cover shadow-md mb-4 transition-transform transform hover:scale-110 hover:border-blue-500"
          />
          <h3 className="text-xl font-bold text-blue-800 mb-1">Daniel Rizvi</h3>
          <p className="text-gray-500 text-xs mb-2">AI Research Scientist</p>
          <p className="text-gray-600 text-sm max-w-full">
          Daniel Rizvi, AI Research Scientist at StuFit from IIT Ropar, drives healthcare innovation with two AI patents and impactful publications. A Developer Advocate, his passion for solving challenges and advancing AI research makes him a vital asset to StuFit.
          </p>
        </div>
      </div>
    </section>
  );
};

export default CEO;
